/* eslint-disable quotes */
<template>
  <exterior-page-content-container>
    <p class='text-2xl font-bold text-center text-primary'>Sign In</p>

    <div class='flex flex-col items-stretch'>
      <a
        href="/api/user/login_google"
        class="flex px-2 py-1 m-1 text-sm font-bold bg-white border border-gray-300 rounded"
      >
        <img src="/img/google/google-icon.png" alt="google logo" class="w-5 h-5 ml-1 mr-3">
        Sign in with Google
      </a>
      <a
        v-if="false"
        href="/api/user/redirect_to_facebook"
        class="`
          flex px-2 py-1 m-1 text-sm font-bold bg-white
          border border-gray-300 rounded text-white
        `"
        style="background-color: #2877f2;"
      >
        <img
          src="/img/facebook/faecbook-icon.png"
          alt="google logo"
          class="flex-shrink-0 h-5 mr-2 w-7"
        >
        Sign in with Facebook
      </a>
    </div>

    <div class='flex flex-row items-center w-full justify-stretch'>
      <hr class='flex-grow' />
      <span class='px-4 text-content'>or</span>
      <hr class='flex-grow' />
    </div>

    <notification-banner
      v-if="errorMessage.length != 0"
      color="red"
    >
      {{ errorMessage }}
    </notification-banner>

    <form id="signup-form" class="flex flex-col" @submit.prevent="signIn">
      <text-input-component
        :value.sync="user.email"
        :valid="textValidator(user.email)"
        label="Email"
        type="email"
        mode="form"
      />
      <text-input-component
        :value.sync="user.password"
        :valid="textValidator(user.password)"
        label="Password"
        type="password"
        mode="form"
      />

      <a
        class='mt-2 underline cursor-pointer text-secondary text-bold'
        @click="$router.push({name:'ForgotPassword'})"
      >
        Forgot Password?
      </a
      >

      <button-large
        class='self-center mt-5'
        color="primary"
      >
        Sign In
      </button-large>
    </form>

    <p class='mt-2 text-content text-bold'>
      Don't have an account?
      <a
        class='underline cursor-pointer text-secondary'
        @click="$router.push({name:'Register'})"
      >
        Create Account
      </a>
    </p>
  </exterior-page-content-container>
</template>

<script>
import ExteriorPageContentContainer from '@/components/shared/containers/ExteriorPageContentContainer.vue';
import NotificationBanner from '@/components/shared/general/NotificationBanner.vue';

import { USER_SIGN_IN } from '@/store/actions';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'SignIn',
  components: {
    ExteriorPageContentContainer,
    NotificationBanner,
    TextInputComponent,
    ButtonLarge,
  },
  data() {
    return {
      formIsValid: true,
      errorMessage: '',
      user: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    textValidator(value) {
      if (this.formIsValid === true) return true;
      if (value !== '') return true;
      return false;
    },
    signIn() {
      if (this.isValid() === false) {
        this.errorMessage = 'Please fill in all fields';
      } else {
        this.executeSignIn();
      }
    },
    executeSignIn() {
      this.$store.dispatch(USER_SIGN_IN, this.user)
        .then(() => {
          this.$router.push({ name: 'Dashboard' });
        })
        .catch((data) => {
          if (data.response.status === 401) {
            this.errorMessage = 'Invalid username or password';
          } else {
            this.errorMessage = 'There appears to be an error. Please try again later';
          }
        });
    },
    isValid() {
      this.formIsValid = true;
      if (this.user.email === '') this.formIsValid = false;
      if (this.user.password === '') this.formIsValid = false;
      return this.formIsValid;
    },
  },
};
</script>
